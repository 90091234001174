import React from "react";

export const Agreement: React.FC<{}> = () => {
  return (
    <div className="agreement-container">
      <div className="agreement-content">
        <div>
          <span>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</span>
          <span>
            г. Москва “15” сентября 2024 г. Настоящая политика обработки
            персональных данных составлена в соответствии с требованиями
            Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и
            определяет порядок обработки персональных данных и меры по
            обеспечению безопасности персональных данных Автономной
            некоммерческой организацией дополнительного профессионального
            образования «Ведантика» (ИНН 7838118476, ОГРН 1237800139345), (далее
            – Оператор). Общие положения Оператор ставит своей важнейшей целью и
            условием осуществления своей деятельности соблюдение прав и свобод
            человека и гражданина при обработке его персональных данных, в том
            числе защиты прав на неприкосновенность частной жизни, личную и
            семейную тайну. Настоящая политика Оператора в отношении обработки
            персональных данных (далее – Политика) применяется ко всей
            информации, которую Оператор может получить о посетителях веб-сайта:
            info@vedantica.pro. 2. Основные понятия, используемые в Политике
            Автоматизированная обработка персональных данных – обработка
            персональных данных с помощью средств вычислительной техники;
            Блокирование персональных данных – временное прекращение обработки
            персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных); Веб-сайт –
            совокупность графических и информационных материалов, а также
            программ для ЭВМ и баз данных, обеспечивающих их доступность в сети
            интернет по сетевому адресу: info@vedantica.pro; Информационная
            система персональных данных — совокупность содержащихся в базах
            данных персональных данных, и обеспечивающих их обработку
            информационных технологий и технических средств; Обезличивание
            персональных данных — действия, в результате которых невозможно
            определить без использования дополнительной информации
            принадлежность персональных данных конкретному Пользователю или
            иному субъекту персональных данных; Обработка персональных данных –
            любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без
            использования таких средств с персональными данными, включая сбор,
            запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных; Оператор – государственный орган,
            муниципальный орган, юридическое или физическое лицо, самостоятельно
            или совместно с другими лицами организующие и (или) осуществляющие
            обработку персональных данных, а также определяющие цели обработки
            персональных данных, состав персональных данных, подлежащих
            обработке, действия (операции), совершаемые с персональными данными;
            Персональные данные – любая информация, относящаяся прямо или
            косвенно к определенному или определяемому Пользователю веб-сайта
            vedantica.pro; Пользователь – любой посетитель веб-сайта
            vedantica.pro; Предоставление персональных данных – действия,
            направленные на раскрытие персональных данных определенному лицу или
            определенному кругу лиц; Распространение персональных данных – любые
            действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц (передача персональных данных) или на
            ознакомление с персональными данными неограниченного круга лиц, в
            том числе обнародование персональных данных в средствах массовой
            информации, размещение в информационно-телекоммуникационных сетях
            или предоставление доступа к персональным данным каким-либо иным
            способом; Трансграничная передача персональных данных – передача
            персональных данных на территорию иностранного государства органу
            власти иностранного государства, иностранному физическому или
            иностранному юридическому лицу; Уничтожение персональных данных –
            любые действия, в результате которых персональные данные
            уничтожаются безвозвратно с невозможностью дальнейшего
            восстановления содержания персональных данных в информационной
            системе персональных данных и (или) результате которых уничтожаются
            материальные носители персональных данных. 3. Оператор может
            обрабатывать следующие персональные данные Пользователя Фамилия,
            имя, отчество; Электронный адрес; Номера телефонов; Также на сайте
            происходит сбор и обработка обезличенных данных о посетителях (в
            т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс
            Метрика и других). Вышеперечисленные данные далее по тексту Политики
            объединены общим понятием Персональные данные. 4. Цели обработки
            персональных данных Цель обработки персональных данных Пользователя
            — информирование Пользователя посредством отправки электронных
            писем; заключение, исполнение и прекращение гражданско-правовых
            договоров; предоставление доступа Пользователю к сервисам,
            информации и/или материалам, содержащимся на веб-сайте. Также
            Оператор имеет право направлять Пользователю уведомления о новых
            продуктах и услугах, специальных предложениях и различных событиях.
            Пользователь всегда может отказаться от получения информационных
            сообщений, направив Оператору письмо на адрес электронной почты
            info@vedantica.pro с пометкой «Отказ от уведомлениях о новых
            продуктах и услугах и специальных предложениях». Обезличенные данные
            Пользователей, собираемые с помощью сервисов интернет-статистики,
            служат для сбора информации о действиях Пользователей на сайте,
            улучшения качества сайта и его содержания. 5. Правовые основания
            обработки персональных данных Оператор обрабатывает персональные
            данные Пользователя только в случае их заполнения и/или отправки
            Пользователем самостоятельно через специальные формы, расположенные
            на сайте vedantica.pro. Заполняя соответствующие формы и/или
            отправляя свои персональные данные Оператору, Пользователь выражает
            свое согласие с данной Политикой. Оператор обрабатывает обезличенные
            данные о Пользователе в случае, если это разрешено в настройках
            браузера Пользователя (включено сохранение файлов «cookie» и
            использование технологии JavaScript). 6. Порядок сбора, хранения,
            передачи и других видов обработки персональных данных Безопасность
            персональных данных, которые обрабатываются Оператором,
            обеспечивается путем реализации правовых, организационных и
            технических мер, необходимых для выполнения в полном объеме
            требований действующего законодательства в области защиты
            персональных данных. Оператор обеспечивает сохранность персональных
            данных и принимает все возможные меры, исключающие доступ к
            персональным данным неуполномоченных лиц. Персональные данные
            Пользователя никогда, ни при каких условиях не будут переданы
            третьим лицам, за исключением случаев, связанных с исполнением
            действующего законодательства. В случае выявления неточностей в
            персональных данных, Пользователь может актуализировать их
            самостоятельно, путем направления Оператору уведомление на адрес
            электронной почты Оператора info@vedantica.pro с пометкой
            «Актуализация персональных данных». Срок обработки персональных
            данных является неограниченным. Пользователь может в любой момент
            отозвать свое согласие на обработку персональных данных, направив
            Оператору уведомление посредством электронной почты на электронный
            адрес Оператора info@vedantica.pro с пометкой «Отзыв согласия на
            обработку персональных данных». 7. Передача персональных данных 1.
            Оператор не раскрывает третьим лицам и не распространяет
            Персональные данные, за исключением следующих случаев: (1) Субъект
            ПД заблаговременно выразил свое согласие на такое раскрытие. (2)
            Передача необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем, по которому является субъект
            Персональных данных, а также для заключения договора по инициативе
            субъекта Персональных данных или договора, по которому он будет
            являться выгодоприобретателем или поручителем. (3) Передача
            необходима для защиты прав и законных интересов Оператора или
            третьих лиц; (4) Передача инициирована субъектом Персональных
            данных. (5) Передача необходима для осуществления правосудия,
            исполнения судебного акта, акта другого органа или должностного
            лица, подлежащих исполнению в соответствии с законодательством РФ об
            исполнительном производстве либо иных предусмотренных действующим
            законодательством РФ случаях. 2. При передаче Персональных данных
            третьим лицам Компания уведомляет указанных лиц об обязанности
            сохранения конфиденциальности Персональных данных и использования их
            лишь в тех целях, для которых они переданы. 3. При передаче
            Персональных данных лицам, обрабатывающим эти данные по поручению
            Компании, в соглашении с таким лицом предусматриваются: (1) перечень
            Персональных данных, обработка которых осуществляется по поручению;
            (2) перечень действий (операций) с Персональными данными, которые
            будут совершаться лицом, осуществляющим обработку Персональных
            данных; (3) допустимые цели обработки данных таким лицом; (4)
            обязанность соблюдать конфиденциальность Персональных данных и
            обеспечивать безопасность Персональных данных при их обработке; (5)
            обязанность соблюдения требований о локализации баз Персональных
            данных граждан при их сборе, а также соблюдать иные требования,
            предусмотренные ч. 5 ст. 18 и ст. 18.1 Закона о персональных данных;
            (6) обязанность по запросу Оператора в течение срока действия
            поручения Оператора (в том числе до начала обработки Персональных
            данных) предоставлять документы и иную информацию, подтверждающие
            принятие мер по обеспечению правомерной обработки Персональных
            данных; (7) обязанность обеспечения безопасности Персональных данных
            при их обработке, включая требования к защите Персональных данных
            техническими и организационными мерами, а также требование об
            уведомлении Оператора о фактах инцидентов информационной
            безопасности, повлекших утечку Персональных данных. 4.
            Предоставление Персональных данных государственным органам
            производится в соответствии с требованиями действующего
            законодательства Российской Федерации. 8. Трансграничная передача
            персональных данных Оператор до начала осуществления трансграничной
            передачи персональных данных обязан убедиться в том, что иностранным
            государством, на территорию которого предполагается осуществлять
            передачу персональных данных, обеспечивается надежная защита прав
            субъектов персональных данных. Трансграничная передача персональных
            данных на территории иностранных государств, не отвечающих
            вышеуказанным требованиям, может осуществляться только в случае
            наличия согласия в письменной форме субъекта персональных данных на
            трансграничную передачу его персональных данных и/или исполнения
            договора, стороной которого является субъект персональных данных. 9.
            Заключительные положения Пользователь может получить любые
            разъяснения по интересующим вопросам, касающимся обработки его
            персональных данных, обратившись к Оператору с помощью электронной
            почты info@vedantica.pro. В данном документе будут отражены любые
            изменения политики обработки персональных данных Оператором.
            Политика действует бессрочно до замены ее новой версией. Актуальная
            версия Политики в свободном доступе расположена в сети Интернет по
            адресу{" "}
            <a href="https://course.vedantica.ru/politika">
              https://course.vedantica.ru/politika
            </a>
          </span>
        </div>
        <div>
          <span>СОГЛАСИЕ</span>
          <span>
            на получение рекламных рассылок Настоящим, действуя свободно, своей
            волей и в своем интересе, ставя галочку в чекбоксе «Я согласен(-а)
            на получение информационных и рекламных материалов» и (или)
            направляя заполненную форму на Сайте: course.vedantica.ru или иных
            страницах сайта с доменными именами «vedantica.pro», а также на
            страницах аккаунтов Исполнителя в социальных сетях (Telegram,
            Вконтакте, WhatsApp, запрещенной в Российской Федерации социальной
            сети Instagram и Facebook, далее – «Сайт»), во исполнение требований
            ч. 1 ст. 18 Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе», я
            даю свое согласие Автономной некоммерческой организации
            дополнительного профессионального образования «Ведантика» (ИНН
            7838118476, ОГРН 1237800139345) (далее – «Исполнитель»), на
            получение мною материалов рекламного и (или) информационного
            характера, рекламных рассылок следующими условиями: 1. Согласие
            дается на получение материалов рекламного и (или) информационного
            характера, рекламных рассылок следующими способами: ● sms-рассылки;
            ● push-сообщения; ● по электронной почте; ● по сетям электросвязи, в
            том числе посредством использования телефонной, факсимильной,
            подвижной радиотелефонной связи; ● сообщения в социальных сетях и
            мессенджерах (Telegram, Вконтакте, WhatsApp, запрещенной в
            Российской Федерации социальной сети Instagram и Facebook). 2.
            Настоящим я гарантию, что указанные мною на Сайте абонентский номер
            телефона и адрес электронной почты принадлежат мне, в случае
            прекращения использования мною абонентского номера телефона и адреса
            электронной почты, указанного на Сайте, я обязуюсь проинформировать
            об этом Исполнителя. Указывая на Сайте абонентский номер телефона и
            адрес электронной почты, принадлежащие третьему лицу, я гарантирую,
            что получил (-а) согласие от указанного третьего лица на получение
            рекламных рассылок от Исполнителя в соответствии с настоящим
            Согласием. 3. Рекламная рассылка осуществляется до отписки от
            информации путем перехода по специальной ссылке «Отписаться от
            рассылки» в рассылаемых письмах или путем направления
            соответствующего запроса на электронную почту: info@vedantica.pro.
            4. Вводя свои данные на Сайте, я подтверждаю, что ознакомлен (-а) с
            политикой обработки персональных данных Исполнителя, размещенной на
            Сайте по ссылке:{" "}
            <a href="https://course.vedantica.ru/politika">
              https://course.vedantica.ru/politika
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
